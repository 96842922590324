<template>
  <div class="mb-3">
    <v-container fluid>
      <div>
        <v-progress-linear
            v-if="this.loading"
            color="primary darken-2"
            indeterminate
        ></v-progress-linear>

        <v-row
            v-for="status in this.statuses"
            :key="status.ID"
            class="py-0 my-0"
            no-gutters
        >
          <v-col cols="12" md="3" class="px-2">
            {{ $t(status.name) }}
          </v-col>

          <v-col cols="12" md="3" class="px-2">
            <v-text-field
                v-model="status.priority"
                :label="$t('generic.lang_Priority')"
                type="number"
                outlined
                min="0"
                dense
                required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" class="px-2">
            <fontawesome-picker
                v-model="status.icon"
                :fieldLabel="$t('generic.lang_icon')"
                dense
            ></fontawesome-picker>
          </v-col>

          <v-col cols="12" md="3" class="px-2">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="status.color"
                    v-bind="attrs"
                    v-on="on"
                    :label="$t('generic.lang_status_color')"
                    autocomplete="off"
                    outlined
                    dense
                    required
                >
                  <template slot="prepend-inner">
                    <v-icon
                        :color="status.color ? status.color : 'transparent'"
                    >
                      mdi-circle
                    </v-icon>
                  </template>
                </v-text-field>
              </template>

              <v-color-picker
                  v-model="status.color"
                  dot-size="18"
                  mode="hexa"
                  hide-canvas
                  hide-inputs
                  hide-sliders
                  show-swatches
                  swatches-max-height="200"
                  :swatches="swatches"
              ></v-color-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" xl="12" lr="12" md="12" sm="12" class="py-0 my-0">
            <v-divider class="py-0 my-0" style="margin-top: -20px !important"/>
          </v-col>
        </v-row>
      </div>

      <v-layout>
        <v-flex class="text-right">
          <v-btn color="success" @click="updateStatuses()">{{$t('generic.lang_apply')}}</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {ENDPOINTS} from "@/config";
import FontawesomePicker from "@/components/common/iconPicker";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
  faBarcode,
  faCheeseburger,
  faCocktail,
  faPersonCarry,
  faCroissant,
  faFish,
  faFrenchFries,
  faGifts,
  faConciergeBell,
  faHamburger,
  faHotdog,
  faMeat,
  faPizzaSlice,
  faSalad,
  faSandwich,
  faShishKebab,
  faSoup,
  faTaco,
} from "@fortawesome/pro-light-svg-icons";

library.add(
    faHotdog,
    faBarcode,
    faCocktail,
    faPersonCarry,
    faMeat,
    faGifts,
    faConciergeBell,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faCroissant,
    faSalad,
    faTaco,
    faSandwich,
    faSoup
);

export default {
  components: {
    FontawesomePicker,
  },

  data() {
    return {
      ENDPOINTS,
      id: null,
      name: "",
      priority: 0,
      selectedIcon: null,
      selectedColor: "",
      showUpdate: false,
      statuses: [],
      loading: false,
      swatches: [
        ["#FF0000", "#AA0000", "#550000", "#030507"],
        ["#FFFF00", "#AAAA00", "#555500", "#00FFA9"],
        ["#00FF00", "#00AA00", "#005500", "#FF6433"],
        ["#00FFFF", "#00AAAA", "#005555", "#FF00FF"],
        ["#0000FF", "#0000AA", "#000055", "#00000000"],
      ],
    };
  },

  mounted() {
    this.loadStatuses();
  },

  methods: {
    loadStatuses() {
      this.loading = true;

      this.axios
          .get(ENDPOINTS.SETTINGS.ROOMPLANER.STATUSES.GET)
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              this.statuses = res.data.statuses;
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch((err) => {
          })
          .finally(() => {
            this.loading = false;
          });
    },

    updateStatuses() {
      this.loading = true;

      this.axios
          .post(ENDPOINTS.SETTINGS.ROOMPLANER.STATUSES.UPDATE, {
            statuses: this.statuses,
          })
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_statusUpdated'),
                color: "success",
              });
              this.loadStatuses();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
