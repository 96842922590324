<template>
  <div>
    <page-title
      :heading="this.$t('settings.lang_nav_settings_roomplaner')"
      :subheading="this.$t('erp.lang_rooms')"
      :icon="icon"
      :show-add-button="this.tab == 0 ? true : false"
      :permission-add="this.tab == 0 ? true : false"
    ></page-title>

    <div class="app-main__inner">
      <v-tabs v-model="tab">
        <v-tab key="rooms"> {{this.$t('erp.lang_rooms')}} </v-tab>
        <v-tab key="statuses"> {{this.$t('generic.lang_status')}} </v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="rooms">
            <rooms-component />
          </v-tab-item>
          <v-tab-item key="statuses">
            <status-component />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle";
import RoomsComponent from "../../../../components/settings/cashier/TablePlaner/RoomsComponent";
import StatusComponent from "../../../../components/settings/cashier/TablePlaner/StatusComponent";
export default {
  name: "TablePlaner",

  components: {
    RoomsComponent,
    StatusComponent,
    PageTitle,
  },

  data() {
    return {
      tab: 0,
      icon: "pe-7s-monitor icon-gradient bg-tempting-azure",
    };
  },
};
</script>

<style scoped>
</style>
